import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import catalogApi from 'api/catalog'
import restockingApi from 'api/restocking'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { usePnlSync } from 'contexts/PnlSync'
import { useCallback, useMemo } from 'react'
import { IApiFilter } from 'types/tableFiltering'

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return restockingApi.getRestocking({page, ...queryKey[1]}).then((res) => {
    return res
  })
}

const syncStateQueryFn = async () => {
  return catalogApi.getSyncState().then((res) => (res.syncedAt ? new Date(res.syncedAt) : null))
}

const useRestocking = () => {
  const syncState = usePnlSync();
  const {data: _} = useQuery({
    queryKey: ['catalog-sync-state'],
    queryFn: syncStateQueryFn,
  })

  const {search, ordering, opFilters: filters} = useFilteringContext()

  const queryKey = useMemo(() => (
    [
    'restocking',
    {
      search,
      ordering,
      filters
    }
    ]
  ), [search, ordering, filters])

  const {data: items, refetch, fetchNextPage, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn,
    initialPageParam: 1,
    select: (data) => data.pages.flatMap((p) => p.items),
    queryKeyHashFn: (key) => JSON.stringify(key),
    getNextPageParam: (lastPage) => lastPage.next,
  })

  const loadNextPage = useCallback(() => {
    if (isFetchingNextPage) return
    if (!hasMore) return
    return fetchNextPage()
  }, [isFetchingNextPage, hasMore, fetchNextPage])

  return {
    items,
    syncState,
    loadingNextPage: isFetchingNextPage,
    loadNextPage,
    load: refetch,
    hasMore,
  }
}

export default useRestocking
