import * as xlsx from "xlsx-js-style"

interface ICol<N = string, T = 'number' | 'text' | 'boolean' | "date"> {
  name: N
  type: T
  default?: T extends 'number' ? number : T extends 'text' ? string : T extends 'boolean' ? boolean : never
}

export type ExcelUploadSchema = readonly ICol[]

class ExcelUpload<T extends ExcelUploadSchema> {
  schema: ExcelUploadSchema
  constructor(schema: T extends ExcelUploadSchema ? T : never) {
    this.schema = schema
  }

  async upload() {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', '.xlsx')
      input.addEventListener('change', () => {
        if (input.files) {
          resolve(input.files[0])
        }
        input.remove()
      })
      input.click()
    })
  }

  load(file: any, type: xlsx.ParsingOptions['type'] = 'file', sheetRows: number | undefined = undefined, ignoreDefinition = false) {
    const wb = xlsx.read(file, { type, sheetRows })
    const ws = wb.Sheets[wb.SheetNames[0]]
    const data = xlsx.utils.sheet_to_json(ws, { header: 1, blankrows: false, rawNumbers: true })
    const [headers, ...content] = data as string[][]

    const cols = this.schema.map((col) => col.name)

    if (ignoreDefinition) {
      return content.map((row) => {
        const obj = {} as any
        headers.forEach((header, index) => {
          obj[header] = row[index]
        })
        return obj
      })
    }

    if (headers.length !== cols.length) throw new Error(`Invalid number of columns: ${headers.length}, was supposed to be ${cols.length}`)

    headers.forEach((header, index) => {
      if (header !== cols[index]) {
        throw new Error(`Column ${index + 1} has invalid name: ${header}, was supposed to be ${cols[index]}`)
      }
    })

    type SchemaItem = typeof this.schema[number];

    type SchemaObject = {
        [K in SchemaItem["name"]]: SchemaItem["type"] extends "text" ? string : SchemaItem["type"] extends "number" ? number : never;
    };

    const out: SchemaObject[] = []

    if (!content.length) throw new Error('No data found')

    content.forEach((row, i) => {
      const obj = {} as any
      headers.forEach((header, index) => {
        const cell = row[index]
        const col = this.schema[index]
        switch (col.type) {
          case 'number':
            try {
              let num = Number(cell)
              if (cell && isNaN(num)) {
                if (col.default !== undefined) {
                  num = col.default as number
                } else {
                  num = "Invalid Value" as unknown as number;
                }
              }
              obj[header] = num
            } catch (e) {
              throw new Error(`Row ${i + 1}, column ${index + 1} has invalid value: ${cell}, was supposed to be a number`)
            }
            break
          case 'boolean':
            obj[header] = Boolean(cell)
            break
          case 'date':
            try {
              // enforce yyyy-mm-dd format
              if (!cell?.trim()) {
                obj[header] = ""
                break;
              }
              const re = /^\d{4}-\d{2}-\d{2}$/;
              if (!re.test(cell)) {
                throw new Error(`Row ${i + 1}, column ${index + 1} has invalid value: ${cell}, was supposed to be a date in yyyy-mm-dd format`)
              }
              const date = new Date(cell)
              if (isNaN(date.getTime())) {
                if (col.default) {
                  obj[header] = new Date(col.default as string).toISOString().split('T')[0]
                } else {
                  obj[header] = "Invalid Value" as unknown as Date;
                }
              } else {
                obj[header] = date.toISOString().split('T')[0]
              }
            } catch (e) {
              throw new Error(`Row ${i + 1}, column ${index + 1} has invalid value: ${cell}, was supposed to be a date`)
            }
            break
          default:
            // if (!cell) throw new Error(`Row ${i + 1}, column ${index + 1} is empty`) // disable null check
            obj[header] = cell
            break
        }
      })
      out.push(obj)
    })

    return out
  }

  toCSV(file: any[], type: xlsx.ParsingOptions['type'] = 'file', sheetRows: number | undefined = undefined) {
    const wb = xlsx.read(file, { type, sheetRows })
    const f = xlsx.write(wb, { type: 'file', bookType: 'csv' })
    return f as File
  }

  downloadTemplate() {
    const wb = xlsx.utils.book_new()
    const ws = xlsx.utils.aoa_to_sheet([this.schema.map((col) => col.name)])
    xlsx.utils.book_append_sheet(wb, ws)
    xlsx.writeFile(wb, 'template.xlsx', { type: 'base64', bookType: 'xlsx' })
  }
}

export default ExcelUpload
