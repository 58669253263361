import { FC } from "react";
import { usNumber } from "utils/formatting";

interface props {
    title: string;
    value?: string;
    color?: string
}

const DashboardTile: FC<props> = ({ title, value, color }) => {
  const loading = value === '...'
  return (
    <div 
    className={[
      "w-full h-[92px] flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary",
      loading && 'animate-pulse'
      ].asClass}
      >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">
        {value ?? "..."}
      </span>
    </div>
  )
}

export default DashboardTile;