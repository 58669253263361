import { FC, ReactNode, useCallback, useMemo } from 'react'
import TH from './TH'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import Empty from 'components/Empty'
import TableProvider from 'contexts/Table/TableProvider'
import Checkbox from 'components/Checkbox'
import useSelect from 'hooks/useSelect'
import usePreferences from 'contexts/Preferences/useContext'
import { ColumnDef, ColumnPinningState, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table'
import TableLoadingRow from './LoadingRow'
import useSaveScroll from 'hooks/useSaveScroll'

export interface TableProps<T extends Record<string, any> = any> {
  items?: T[]
  disabled?: boolean
  select?: ReturnType<typeof useSelect>
  noSelectAll?: boolean
  renderRow: (row: Row<T>, index: number) => ReactNode
  onBottom?: () => void
  initialPinState?: ColumnPinningState
  name: string
  columns: ColumnDef<T>[]
  saveScrollKey?: string
  locked?: boolean
  loading?: boolean
  loadingNext?: boolean
  extra?: any
}

const Table: FC<TableProps> = ({
  name,
  columns: defaultColumns,
  saveScrollKey,
  initialPinState = {},
  items,
  select,
  loading,
  loadingNext,
  renderRow,
  onBottom,
  disabled,
  noSelectAll,
  extra,
  locked = false,
}) => {
  const { preferences } = usePreferences()
  const withSelection = useMemo(() => !!select, [select])

  const baseColumns = useMemo(() => {
    const preference = preferences?.tables?.[name]
    if (!preference)
      return {
        columns: defaultColumns,
        pins: initialPinState,
      }
    const columns = preference.columns
      .map(({ id }) => {
        const def = defaultColumns.find((col) => col.id === id)
        if (!def) return null
        return def
      })
      .filter(Boolean) as ColumnDef<any>[]
    return {
      columns,
      pins: {
        left: preference.columns.filter((pref) => pref.pin === 'left').map((pref) => pref.id),
        right: preference.columns.filter((pref) => pref.pin === 'right').map((pref) => pref.id),
      },
    }
  }, [preferences, defaultColumns, initialPinState, name])

  const { columns, pinState } = useMemo(() => {
    const originalSelectionColDef = baseColumns.columns.find(({ id }) => id === 'selection')
    const columns = [...(withSelection && !originalSelectionColDef ? [{ id: 'selection', size: 32 }] : []), ...baseColumns.columns]
    const pinState = {
      left: ['selection', ...(baseColumns.pins.left || [])],
      right: [...(baseColumns.pins.right || [])],
    }
    return { columns, pinState }
  }, [baseColumns, withSelection])

  const onBottomCb = useCallback(() => {
    if (onBottom) onBottom()
  }, [onBottom])
  const scrollRef = useBottomScrollListener<HTMLDivElement>(onBottomCb, { triggerOnNoScroll: false, offset: 1000, debounce: 500, debounceOptions: { leading: true } })
  const checkState = useMemo(() => (select?.allSelected ? (select.selected.length ? 'semi' : 'checked') : 'unchecked'), [select])
  const saveScrollRef = useSaveScroll(saveScrollKey)

  const loaderItems = useMemo(() => Array(20).fill(undefined), [])

  const data = useMemo(() => (items && !loading ? (loadingNext ? [...items, ...loaderItems] : items.length ? items : [undefined]) : loaderItems), [items, loading, loadingNext, loaderItems])

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    state: {
      columnPinning: pinState,
    },
  })

  const renderRowPossiblyLoading = useCallback(
    (row: Row<any>, index: number) => {
      if (row.original === undefined) {
        return <TableLoadingRow key={row.id} row={row} />
      }

      return renderRow(row, index)
    },
    [renderRow]
  )

  return (
    <TableProvider table={table} select={select} extra={extra} locked={locked}>
      <div className="w-full h-full overflow-hidden relative">
        {!!items && !items?.length && (
          <div className="flex flex-col w-full items-center justify-center absolute inset-0 z-[100] backdrop-blur-[256px]">
            <Empty text="No Data" />
          </div>
        )}
        <div
          ref={(e) => {
            (scrollRef as any).current = e
            saveScrollRef.current = e
          }}
          className="w-full h-full overflow-auto"
        >
          <table
            style={{
              width: table.getTotalSize(),
              minWidth: '100%',
            }}
            className={['h-full w-full', disabled && 'grayscale pointer-events-none cursor-default'].asClass}
          >
            <thead className="sticky -top-px z-[100]">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (header.id === 'selection') {
                      return noSelectAll ? (
                        <TH key={header.id} header={{ header: '', id: 'selection' }} column={header.column} />
                      ) : (
                        <TH key={header.id} header={{ header: '', id: 'selection', meta: { filters: 'string' } }} column={header.column} onClick={select?.onSelectAllClick}>
                          <Checkbox checked={checkState !== 'unchecked'} semichecked={checkState === 'semi'} />
                        </TH>
                      )
                    }
                    return <TH key={header.id} header={header.column.columnDef} column={header.column} />
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="overflow-y-auto">
              {table.getRowModel().rows.map(renderRowPossiblyLoading)}
              {!!items && <tr />}
            </tbody>
          </table>
        </div>
      </div>
    </TableProvider>
  )
}

export default Table
