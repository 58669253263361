import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Tooltip from './Tooltip'
import useAsinSupplyChain from 'hooks/useAsinSupplyChain'
import Icon from 'assets/icons/iconset'
import { usNumber } from 'utils/formatting'

interface props {
  asin: string
}

const AsinSupplyChainButton: FC<props> = ({ asin }) => {
  const [open, setOpen] = useState(false)
  const supplyChainItems = useAsinSupplyChain(asin, open)

  useEffect(() => {
    if (open) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setOpen(false)
        }
      }
      const clickListener = (e: MouseEvent) => {
        setOpen(false)
      }
      window.addEventListener('keydown', listener)
      window.addEventListener('click', clickListener)
      return () => {
        window.removeEventListener('keydown', listener)
        window.removeEventListener('click', clickListener)
      }
    }
  }, [open])

  const futureInStockDate = useCallback((leadTime: number) => {
    const date = new Date()
    date.setDate(date.getDate() + leadTime)
    return date.toLocaleDateString()
  }, [])

  const overUnderBuyingClass = useCallback((qty: number) => {
    if (qty > 0) return 'bg-red-200'
    if (qty < 0) return 'bg-yellow-200'
    return ''
  }, [])

  const suppliers = useMemo(() => {
    return Array.from(new Set(supplyChainItems?.map((item) => item.suppliers).flat()))
  }, [supplyChainItems])

  const totalQty = useMemo(() => {
    return supplyChainItems?.reduce((acc, item) => acc + item.totalQty, 0)
  }, [supplyChainItems])

  const totalValue = useMemo(() => {
    return supplyChainItems?.reduce((acc, item) => acc + +item.totalValue, 0)
  }, [supplyChainItems])

  const overUnderBuying = useMemo(() => {
    return supplyChainItems?.reduce((acc, item) => acc + +item.overUnderBuyingValue, 0)
  }, [supplyChainItems])

  const amazonInventory = useMemo(() => {
    return supplyChainItems?.reduce((acc, item) => acc + item.amazonInventory, 0)
  }, [supplyChainItems])

  const amazonInventoryValue = useMemo(() => {
    return supplyChainItems?.reduce((acc, item) => acc + item.amazonInventoryValue, 0)
  }, [supplyChainItems])

  const inStockLeadTime = useMemo(() => {
    if (amazonInventory) return 'In Stock'
    if (!supplyChainItems) return 'No Orders'
    const leadTime = Math.min(
      ...supplyChainItems.map((item) => Math.min(...[...(item.workingPos || []), ...(item.openPOs || []), ...(item.inboundPOs || []), ...(item.inWarehouse || [])].map((po) => +po.lead_time)))
    )
    if (leadTime === Infinity) return 'No Orders'
    return futureInStockDate(leadTime)
  }, [supplyChainItems, futureInStockDate, amazonInventory])

  return (
    <Tooltip
      open={open}
      text={
        <div className="flex flex-col">
          <span className="text-sm font-medium text-text-primary">Totals</span>
          <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Qty</th>
                  <th>Cogs</th>
                  <th>Over/Under Buying</th>
                </tr>
              </thead>
              <tbody>
                {!supplyChainItems && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {!!supplyChainItems?.length && (
                  <tr>
                    <td>{suppliers?.join(', ')}</td>
                    <td>{usNumber(totalQty, 0)}</td>
                    <td>$ {usNumber(totalValue, 2)}</td>
                    <td className={overUnderBuyingClass(overUnderBuying || 0)}>$ {usNumber(overUnderBuying)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <span className="text-sm font-medium text-text-primary mt-4">Working Orders</span>
          <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Qty</th>
                  <th>Submitted Date</th>
                  <th>Cogs</th>
                  <th>Over/Under Buying</th>
                  <th>Future In-Stock Date</th>
                </tr>
              </thead>
              <tbody>
                {!supplyChainItems && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {supplyChainItems?.map((item) => {
                  return [...(item.workingPos || []), ...(item.openPOs || [])]?.map((po) => (
                    <tr key={item.asin + item.id + po.supplier}>
                      <td>{item.asin}</td>
                      <td>{usNumber(po.qty, 0)}</td>
                      <td>{new Date(po.submittedDate).toLocaleString()}</td>
                      <td>$ {usNumber(item.workingOrderValue, 2)}</td>
                      <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                      <td>{futureInStockDate(+po.lead_time)}</td>
                    </tr>
                  ))
                })}
              </tbody>
            </table>
          </div>
          <span className="text-sm font-medium text-text-primary mt-4">Inbound Shipments</span>
          <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Qty</th>
                  <th>Submitted Date</th>
                  <th>Cogs</th>
                  <th>Over/Under Buying</th>
                  <th>Future In-Stock Date</th>
                </tr>
              </thead>
              <tbody>
                {!supplyChainItems && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {supplyChainItems?.map((item) => {
                  return item.inboundPOs?.map((po) => (
                    <tr key={po.supplier + item.id}>
                      <td>{po.supplier}</td>
                      <td>{usNumber(po.qty, 0)}</td>
                      <td>{new Date(po.submittedDate).toLocaleString()}</td>
                      <td>$ {usNumber((po.qty * item.inboundOrderValue) / item.inboundOrderQty, 2)}</td>
                      <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                      <td>{futureInStockDate(+po.lead_time)}</td>
                    </tr>
                  ))
                })}
              </tbody>
            </table>
          </div>
          <span className="text-sm font-medium text-text-primary mt-4">In Warehouse</span>
          <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Qty</th>
                  <th>Submitted Date</th>
                  <th>Cogs</th>
                  <th>Over/Under Buying</th>
                  <th>Future In-Stock Date</th>
                </tr>
              </thead>
              <tbody>
                {!supplyChainItems && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {supplyChainItems?.map((item) => {
                  return item.inWarehouse?.map((po) => (
                    <tr key={po.supplier + item.id}>
                      <td>{po.supplier}</td>
                      <td>{usNumber(po.qty, 0)}</td>
                      <td>{new Date(po.submittedDate).toLocaleString()}</td>
                      <td>$ {usNumber((po.qty * item.warehouseOrderValue) / item.warehouseOrderQty, 2)}</td>
                      <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                      <td>{futureInStockDate(+po.lead_time)}</td>
                    </tr>
                  ))
                })}
              </tbody>
            </table>
          </div>
          <span className="text-sm font-medium text-text-primary mt-4">At Amazon</span>
          <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Suppliers</th>
                  <th>Qty</th>
                  <th>Cogs</th>
                  <th>Over/Under Buying</th>
                  <th>Future In-Stock Date</th>
                </tr>
              </thead>
              <tbody>
                {!supplyChainItems && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Loading...
                    </td>
                  </tr>
                )}
                {!!supplyChainItems?.length && (
                  <tr>
                    <td>{suppliers?.join(', ')}</td>
                    <td>{usNumber(amazonInventory, 0)}</td>
                    <td>$ {usNumber(amazonInventoryValue, 2)}</td>
                    <td className={overUnderBuyingClass((amazonInventory || 0) * (overUnderBuying || 0))}>$ {usNumber(((amazonInventory || 0) * (overUnderBuying || 0)) / +(totalQty || 1))}</td>
                    <td>{inStockLeadTime}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      }
      className="!z-[1000] !max-w-none rounded-lg !border-2 !bg-surface-primary border-text-primary p-4 max-h-[50%] overflow-y-auto"
      position="bottom-start"
    >
      <button
        className="p-1 hover:bg-slate-100 rounded transition-colors"
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
      >
        <Icon name="Storefront" className="w-5 h-5" />
      </button>
    </Tooltip>
  )
}

export default AsinSupplyChainButton
