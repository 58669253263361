import Icon from 'assets/icons/iconset'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import {FC, PropsWithChildren, useCallback } from 'react'
import ColFilterSelect from './ColFilterSelect'
import { Column, ColumnDef } from '@tanstack/react-table'
import { getCommonPinningStyles } from './pinningStyles'

interface props {
  header: ColumnDef<any, unknown>
  column: Column<unknown>
  rowSpan?: number
  onClick?: () => void
}

const TH: FC<PropsWithChildren<props>> = ({ header, column, children, onClick, rowSpan = 1 }) => {
  const meta = (header?.meta || {}) as {queryField?: string, filters?: string}
  const field = meta?.queryField || header.id as string
  const divider = field === '___divider___'
  const filterable = !!field && !!meta?.filters
  const sortable = (filterable && ['string', 'number', 'date', 'boolean'].includes(meta?.filters as string)) || meta?.queryField

  const { ordering, setOrdering, opFilters } = useFilteringContext()

  const isCol = ordering?.by === field
  const isDesc = ordering?.direction === -1

  const onOrderingClick = useCallback(() => {
    if (isCol) {
      setOrdering((old) => old && { by: field, direction: (old.direction * -1) as -1 | 1 })
    } else {
      setOrdering({ by: field, direction: -1 })
    }
  }, [field, isCol, setOrdering])

  const activeFilter = !!opFilters?.[field]

  if (typeof header.header === "function") {
    return null
  }

  return (
    <th
      className={[!divider ? 'bg-surface-secondary' : "bg-border-primary", !!onClick && "cursor-pointer"].asClass}
      style={{...getCommonPinningStyles(column)}}
      onClick={onClick}
      rowSpan={rowSpan}
    >
      {children ? (
        children
      ) : (
        <div className="flex items-center gap-4 min-w-max w-full">
          <span className="min-w-max grow">{header.header?.toString()}</span>
          {sortable && (
            <button className={['p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary', ordering?.by === field && 'bg-surface-primary'].asClass} onClick={onOrderingClick}>
              {ordering?.by !== field && <Icon name="CaretRight" className="rotate-90 transition-all" />}
              {ordering?.by === field && <Icon name="CaretRight" className={[isDesc ? 'rotate-90 transition-all' : '-rotate-90 transition-all'].asClass} />}
            </button>
          )}
          {filterable && (
            <ColFilterSelect
              title={header.header?.toString() as string}
              field={field}
              anchor={
                <div className={['p-0.5 rounded-lg cursor-pointer hover:bg-surface-primary transition-colors hover:text-text-primary', activeFilter ? 'text-brand-primary' : 'text-text-primary'].asClass}>
                  <Icon name="Funnel" />
                </div>
              }
              dbType={meta.filters as any}
            />
          )}
        </div>
      )}
    </th>
  )
}

export default TH
