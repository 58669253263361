import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { HousedAsin, HousedAsinInput } from 'types/housedAsins'
import housedAsinsApi from 'api/housedAsins'
import AmazonProductImage from 'components/AmazonProductImage'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { usNumber } from 'utils/formatting'
import TR from 'components/tables/TR'
import { IPrepCost } from 'types/prepCosts'
import SimilarItems from './SimilarItems'
import { useQueryClient } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { IBuyer } from 'types/buyers'
import { orderTypes, storageTypes } from 'constants/badgeSelect'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import AsinSupplyChainButton from 'components/SupplyChain'

interface props {
  row: Row<HousedAsin>
  onDone: (asin?: HousedAsin) => void
  nested?: boolean
}

const Item: FC<props> = ({ onDone, row, nested }) => {
  const item = row.original
  const { extra, locked, select } = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...item,
  } as HousedAsin)
  const supplierNames = extra?.supplierNames || []
  const prepCosts = extra?.prepCosts || []
  const availableBuyers = extra?.availableBuyers || []
  const [showSimilar, setShowSimilar] = useState(false)
  const qc = useQueryClient()

  useEffect(() => {
    setValues({ ...item, buyBoxPercentage: item.buyBoxPercentage ? item.buyBoxPercentage * 100 : null })
  }, [item])

  const update = useCallback(
    (overrides?: Partial<HousedAsinInput>) => {
      if (submitting) return
      if (locked) return
      setSubmitting(true)
      const update = { ...values, ...overrides }
      update.buyBoxPercentage = update.buyBoxPercentage ? update.buyBoxPercentage / 100 : null
      housedAsinsApi
        .updateHoused(item.id, update as HousedAsinInput)
        .then(([res]) => {
          onDone(res)
          if (!nested) {
            qc.invalidateQueries({
              queryKey: ['housed', 'housed', 'similar', item.asin],
            })
          }
        })
        .finally(() => setSubmitting(false))
    },
    [item, locked, onDone, qc, values, submitting, nested]
  )

  const onSubmit = () => {
    update()
    return false
  }

  const hasASINError = !item.weightUnit

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const length = item?.dimensions?.length
  const width = item?.dimensions?.width
  const height = item?.dimensions?.height
  const sizeUnit = item?.dimensions?.unit
  const weight = item?.weightValue
  const weightUnit = item?.weightUnit

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const isMissingValues = useMemo(() => {
    if (!values.supplier) return true
    if (!values.prepCost) return true
    if (!values.asin) return true
    if (!values.sku) return true
    if (!values.vendorSKU) return true
    if (!values.upc) return true
    if (!values.caseQty) return true
    if (!values.unitCost) return true
    if (!values.bundle) return true
    if (!values.sellable) return true
    // if (!values.targetPrice) return true
  }, [values])

  const cellRenderers: RowRenderer<HousedAsin> = {
    selection: (cell) =>
      !!select && (
        <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
          <div className={['flex items-center gap-2', nested && 'pl-4'].asClass}>
            {!!select && (
              <button onClick={() => select?.onSelectClick(item.id)}>
                <Checkbox checked={allSelected ? !isChecked : isChecked} />
              </button>
            )}
            <button className="button-icon-tertiary !p-1 cursor-pointer" disabled={submitting} onClick={() => onValueChange('starred', !item.starred)(true)}>
              <Icon name="Badge" className={item.starred ? 'text-yellow-400' : 'text-text-secondary'} />
            </button>
            {!nested && (
              <button className="!p-1 button-tertiary pointer-events-auto" onClick={() => setShowSimilar((old) => !old)}>
                <Icon name="CaretRight" className={['w-4 h-4 transition-transform', showSimilar && 'rotate-45'].asClass} />
              </button>
            )}
          </div>
        </td>
      ),
    image: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <AmazonProductImage asin={item.asin} size={48} imageSize={96} />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={values.asin} readOnly />
          {!!values.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {!!item.asin && <KeepaHistoryButton asin={item.asin} />}
          {!!item.asin && <AsinSupplyChainButton asin={item.asin} />}
        </div>
      </td>
    ),
    filledOut: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect selected={item.filledOut ? 'Yes' : 'No'} badges={['No', 'Yes']} onSelect={() => {}} editable={false} />
      </td>
    ),
    activeListing: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect selected={item.activeListing ? 'Yes' : 'No'} badges={['No', 'Yes']} onSelect={() => {}} editable={false} />
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-32">
        <input type="text" value={values.title || ''} readOnly />
      </td>
    ),
    supplier: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect
          selected={item.supplier || ''}
          badges={supplierNames}
          onSelect={(supplier) => {
            onValueChange('supplier', supplier)(true)
          }}
          editable={!locked && !submitting}
        />
      </td>
    ),
    upc: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" readOnly={locked} type="text" value={values.upc || ''} onChange={(e) => onValueChange('upc', e.target.value)()} />
      </td>
    ),
    vendorSKU: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" readOnly={locked} value={values.vendorSKU || ''} onChange={(e) => onValueChange('vendorSKU', e.target.value)()} />
      </td>
    ),
    sku: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" readOnly={locked} value={values.sku} onChange={(e) => onValueChange('sku', e.target.value)()} />
      </td>
    ),
    fba: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect
          selected={item.fba ? 'Yes' : 'No'}
          badges={['No', 'Yes']}
          onSelect={(fbaString) => {
            onValueChange('fba', fbaString === 'Yes')(true)
          }}
          editable={!locked}
        />
      </td>
    ),
    createdAt: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" readOnly={true} value={new Date(item.createdAt).toLocaleDateString()} />
      </td>
    ),
    caseQty: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" type="number" readOnly={locked} value={values.caseQty} onChange={onNumberChange((val) => onValueChange('caseQty', val)())} placeholder="0" />
      </td>
    ),
    unitCost: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.unitCost || ''} onChange={onNumberChange((val) => onValueChange('unitCost', val)())} placeholder="0.00" />
        </div>
      </td>
    ),
    bundle: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input className="table-input" readOnly={locked} type="number" value={values.bundle} onChange={onNumberChange((val) => onValueChange('bundle', val)())} placeholder="0" />
      </td>
    ),
    sellable: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input
          className="table-input"
          readOnly={locked}
          type="number"
          value={values.sellable}
          onChange={onNumberChange((val) => onValueChange('sellable', val)())}
          placeholder={usNumber(values.keepaEstimatedSellable, 0)}
        />
      </td>
    ),
    buyBoxPercentage: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input
          className="table-input"
          readOnly={locked}
          type="number"
          min={0}
          max={100}
          value={values.buyBoxPercentage || ''}
          onChange={onNumberChange((val) => onValueChange('buyBoxPercentage', val)())}
          placeholder={usNumber(+(values.globalBuyBoxPercentage || 1) * 100, 0)}
        />
        %
      </td>
    ),
    buyer: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect
          selected={values.buyer || ''}
          badges={availableBuyers?.map((buyer: IBuyer) => buyer.name) || []}
          onSelect={(buyer) => {
            setValues((old) => ({ ...old, buyer }))
            update({ buyer })
          }}
          editable={!locked && !submitting}
        />
      </td>
    ),
    prepCost: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.prepCost || ''}
            badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
            onSelect={(prepCost) => {
              setValues((old) => ({ ...old, prepCost }))
              update({ prepCost })
            }}
            singleColor="blue"
            editable={!locked && !submitting}
          />
          <span>${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.prepCost)?.amount, 2)}</span>
        </div>
      </td>
    ),
    asinCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.asinCost)}
      </td>
    ),
    shippingCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.shippingCost)}
      </td>
    ),
    landedCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.landedCost)}
      </td>
    ),
    price: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.price)}
      </td>
    ),
    targetPrice: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.targetPrice || ''} onChange={onNumberChange((val) => onValueChange('targetPrice', val)())} placeholder="0.00" />
        </div>
      </td>
    ),
    net: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.net)}
      </td>
    ),
    gross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.gross < 0 ? 'text-red-500' : item.gross > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.gross)}
      </td>
    ),
    totalGross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.totalGross < 0 ? 'text-red-500' : item.totalGross > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.totalGross)}
      </td>
    ),
    margin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.margin !== null ? item.margin : null, 2)}%
      </td>
    ),
    roi: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.roi !== null ? item.roi : null, 2)}%
      </td>
    ),
    salesRank: (cell) => (
      <td key={cell.id} className={[hasASINError && 'bg-red-200'].asClass} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>{item.salesRank ? usNumber(item.salesRank, 0) : '-'}</span>
        </div>
      </td>
    ),
    dimensionTier: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={[hasASINError && 'bg-red-200'].asClass}>
        {item.dimensionTier || 'STANDARD'}
      </td>
    ),
    storageType: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.storageType || ''}
            badges={storageTypes}
            onSelect={(storageType: any) => {
              setValues((old) => ({ ...old, storageType }))
              update({ storageType })
            }}
            editable={!locked && !submitting}
          />
        </div>
      </td>
    ),
    orderType: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.orderType || ''}
            badges={orderTypes}
            onSelect={(orderType: any) => {
              setValues((old) => ({ ...old, orderType }))
              update({ orderType })
            }}
            editable={!locked && !submitting}
          />
        </div>
      </td>
    ),
    weightValue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={[hasASINError && 'bg-red-200'].asClass}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(weight)} <span className="font-normal">{weightUnit || '-'}</span>
          </span>
        </div>
      </td>
    ),
    dimensions: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={[hasASINError && 'bg-red-200'].asClass}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(length)} x {usNumber(width)} x {usNumber(height)} <span className="font-normal">{sizeUnit || '-'}</span>
          </span>
        </div>
      </td>
    ),
  }

  return (
    <>
      <TR className={['relative', submitting && 'animate-pulse', nested && '[&>td]:bg-surface-secondary', !nested && isMissingValues && '[&>td]:bg-red-100'].asClass} onBlur={onSubmit}>
        {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
      </TR>
      {!nested && <SimilarItems row={row} housed={item.id} asin={item.asin} open={showSimilar} onDone={onDone} />}
    </>
  )
}

export default Item
