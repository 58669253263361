import { useQuery } from '@tanstack/react-query'
import restockingApi from 'api/restocking'
import { FC } from 'react'
import { usNumber } from 'utils/formatting'

interface DashboardTileProps {
  title: string
  value: string | number
  color: string
}

export const DashboardTile: FC<DashboardTileProps> = ({ title, value, color }) => {
  const loading = value === '...'
  return (
    <div 
    className={[
      "w-full h-[92px] flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary",
      loading && 'animate-pulse'
      ].asClass}
      >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">
        $&nbsp;{typeof value === 'string' ? value : usNumber(value)}
      </span>
    </div>
  )
}

const Dashboard: FC = ({}) => {
  const {data: dashboard} = useQuery({
    queryKey: ["restocking", "overview"],
    queryFn: restockingApi.getOverview
  })
  const tiles = [
    {
      title: 'Restock Revenue Value',
      value: dashboard ? Number(dashboard?.revenue) : '...',
      color: '#8F20FF',
    },
    {
      title: 'Restock Net Proceeds',
      value: dashboard ? Number(dashboard?.net) : '...',
      color: '#F0A30D',
    },
    {
      title: 'Restock Cost Of Goods',
      value: dashboard ? Number(dashboard?.cogs) : '...',
      color: '#52CBFF',
    },
    {
      title: 'Restock Potential Profits',
      value: dashboard ? Number(dashboard.profit) : '...',
      color: '#12B76A',
    },
  ]

  return (
    <div className="grid grid-cols-4 w-full gap-4 pt-4 px-4">
      {tiles.map((tile, i) => (
        <DashboardTile key={i} {...tile} />
      ))}
    </div>
  )
}

export default Dashboard
