import { FC, PropsWithChildren, ReactNode } from "react"
import { Tooltip as MaterialTooltip } from "@material-tailwind/react"
import { placement } from "@material-tailwind/react/types/components/menu"

interface props {
    text: ReactNode
    position: placement
    className?: string
    open?: boolean
    interactive?: boolean
}

const Tooltip: FC<PropsWithChildren<props>> = ({ children, text, position, className, open = undefined, interactive = false }) => {
  return (
    <MaterialTooltip
      open={open}
      interactive={interactive}
      hidden={!text}
      content={text}
      className={[
        "bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line",
        className,
      ].asClass}
      placement={position}
      animate={{
        mount: { scale: 1 },
        unmount: { scale: 0 },
      }}
    >
      <div className="cursor-pointer">
        {children}
      </div>
    </MaterialTooltip>
  )
}

export default Tooltip
