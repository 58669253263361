import { FC } from 'react'
import { HousedAsin } from 'types/housedAsins'
import AmazonProductImage from 'components/AmazonProductImage'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import { usNumber } from 'utils/formatting'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { Row } from '@tanstack/react-table'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { RowRenderer } from 'types/tables'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import AsinSupplyChainButton from 'components/SupplyChain'

interface props {
  row: Row<HousedAsin>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original
  const { select, extra } = useTableContext()
  const supplierNames = extra?.supplierNames || []

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<HousedAsin> = {
    selection: (cell) =>
      !!select && (
        <td key={cell.id} onClick={() => select?.onSelectClick(item.id)} style={{ ...getCommonPinningStyles(cell.column) }}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      ),
    supplier: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <BadgeSelect selected={item.supplier || ''} badges={supplierNames} onSelect={() => {}} editable={false} />
      </td>
    ),
    image: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <AmazonProductImage asin={item.asin} size={48} imageSize={96} />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2">
          <input type="text" className="w-[7rem]" value={item.asin} readOnly />
          {!!item.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {!!item.asin && <KeepaHistoryButton asin={item.asin} />}

          {!!item.asin && <AsinSupplyChainButton asin={item.asin} />}
        </div>
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="!w-[256px] !max-w-[256px] truncate">
        {item.title}
      </td>
    ),
    asinCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.asinCost)}
      </td>
    ),
    totalGross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.totalGross < 0 ? 'text-red-500' : item.totalGross > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.totalGross)}
      </td>
    ),
    margin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.margin !== null ? item.margin : null, 2)}%
      </td>
    ),
    roi: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.roi !== null ? item.roi : null, 2)}%
      </td>
    ),
  }

  return <tr className="relative">{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</tr>
}

export default Item
