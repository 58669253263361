import { Row } from '@tanstack/react-table'
import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import ColorGroupToggle from 'components/ColorGroupToggle'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import AsinSupplyChainButton from 'components/SupplyChain'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import TR from 'components/tables/TR'
import { storageTypes } from 'constants/badgeSelect'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useEffect, useState } from 'react'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import { RowRenderer } from 'types/tables'
import { usNumber } from 'utils/formatting'

interface props {
  row: Row<MasterUPCCatalogItem>
  updateItem: (item: Partial<MasterUPCCatalogItem>) => void
}

const Item: FC<props> = ({ row, updateItem }) => {
  const { supplier, vendorSKU, upc, unitCost, caseCost, casePack, title, uom, size } = row.original
  const item = row.original
  const { extra } = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState<MasterUPCCatalogItem>({ ...item })

  const createHoused = extra?.onCreateHoused
  const createWorkingPO = extra?.onCreateWorkingPO

  useEffect(() => {
    setValues({ ...item })
  }, [item])

  const { length, width, height, unit } = item.dimensions || {}

  const onSubmit = async (overrides?: Partial<MasterUPCCatalogItem>) => {
    if (submitting) return
    if (!item.asin) return
    const updates = { ...values, ...(overrides || {}) }
    let bundle = Number(updates.bundle)
    if (isNaN(bundle)) bundle = 0
    let sellable = Number(updates.sellable)
    if (isNaN(sellable)) sellable = 0
    bundle = Math.floor(bundle)
    sellable = Math.floor(sellable)
    const group = updates.group || null
    const storageType = updates.storageType || null
    setSubmitting(true)
    housedAsinsApi
      .updateMasterCatalogItem(item.id, item.asin, { bundle, sellable, group, storageType })
      .then((updated) => updateItem(updated))
      .finally(() => setSubmitting(false))
  }

  const cellRenderers: RowRenderer<MasterUPCCatalogItem> = {
    supplier: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={supplier || '---'} readOnly />
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={title || '---'} readOnly />
      </td>
    ),
    size: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {size || '---'}
      </td>
    ),
    uom: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {uom || '---'}
      </td>
    ),
    upc: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {upc || '---'}
      </td>
    ),
    vendorSKU: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {vendorSKU || '---'}
      </td>
    ),
    unitCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(Number(unitCost))}
      </td>
    ),
    casePack: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {Number(casePack)}
      </td>
    ),
    caseCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(Number(caseCost))}
      </td>
    ),
    searchStatus: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <span
          className={
            [
              'cursor-pointer px-2 py-1 rounded text-xs font-medium',
              item.searchStatus === 'not found' ? 'bg-red-100 text-red-500' : item.searchStatus === 'not synced' ? 'bg-yellow-100 text-yellow-500' : 'bg-green-100 text-green-500',
            ].asClass
          }
        >
          {item.searchStatus.toUpperCase()}
        </span>
      </td>
    ),
    image: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.asin ? <AmazonProductImage asin={item.asin} size={48} imageSize={96} /> : null}
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item.asin || 'N/A'} readOnly />
          {!!item.asin && (
            <a href={`https://amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
              <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {!!item.asin && <KeepaHistoryButton asin={item.asin} />}

          {!!item.asin && <AsinSupplyChainButton asin={item.asin} />}
        </div>
      </td>
    ),
    amazonTitle: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-32">
        <input type="text" value={item.amazonTitle || ''} readOnly />
      </td>
    ),
    bundle: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }} onBlur={() => onSubmit()}>
        <input
          className="table-input"
          readOnly={!item.asin}
          type="text"
          value={values.bundle || ''}
          onChange={(e) => setValues((old) => ({ ...old, bundle: e.target.value as unknown as number }))}
          placeholder={(item.amazonBundle || '---').toString()}
        />
      </td>
    ),
    sellable: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }} onBlur={() => onSubmit()}>
        <input
          className="table-input"
          readOnly={!item.asin}
          type="text"
          value={values.sellable || ''}
          onChange={(e) => setValues((old) => ({ ...old, sellable: e.target.value as unknown as number }))}
          placeholder={usNumber(values.keepaEstimatedSellable, 0)}
        />
      </td>
    ),
    group: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <ColorGroupToggle
          value={values.group || 'white'}
          onChange={(group) => {
            setValues((old) => ({ ...old, group }))
            onSubmit({ group })
          }}
          disabled={submitting}
        />
      </td>
    ),
    asinCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.asinCost)}
      </td>
    ),
    shippingCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.shippingCost)}
      </td>
    ),
    landedCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.landedCost)}
      </td>
    ),
    price: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.price)}
      </td>
    ),
    net: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.net)}
      </td>
    ),
    gross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={+(item.gross || 0) < 0 ? 'text-red-500' : +(item.gross || 0) > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.gross)}
      </td>
    ),
    totalGross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={+(item.totalGross || 0) < 0 ? 'text-red-500' : +(item.totalGross || 0) > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.totalGross)}
      </td>
    ),
    margin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.margin !== null ? item.margin : null, 2)}%
      </td>
    ),
    roi: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.roi !== null ? item.roi : null, 2)}%
      </td>
    ),
    dimensionTier: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.dimensionTier || 'STANDARD'}
      </td>
    ),
    weight: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(item.weight)} <span className="font-normal">{item.weightUnit || '-'}</span>
          </span>
        </div>
      </td>
    ),
    dimensions: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <input type="text" value={`${usNumber(length)} x ${usNumber(width)} x ${usNumber(height)} ${unit || '-'}`} readOnly />
        </div>
      </td>
    ),
    salesRank: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>{item.salesRank ? usNumber(item.salesRank, 0) : '-'}</span>
        </div>
      </td>
    ),
    salesCategory: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <input type="text" value={item.salesCategory || '---'} readOnly />
        </div>
      </td>
    ),
    storageType: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.storageType || ''}
            badges={storageTypes}
            onSelect={(storageType: any) => {
              setValues((old) => ({ ...old, storageType }))
              onSubmit({ storageType })
            }}
            editable={!submitting}
          />
        </div>
      </td>
    ),
    hasHoused: (cell) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex gap-1 items-center">
          <BadgeSelect selected={item.hasHoused ? 'Yes' : 'No'} badges={['No', 'Yes']} onSelect={() => {}} editable={false} />
          {item.hasHoused ? (
            <button className="button-secondary w-max !py-1 !px-2" onClick={() => createWorkingPO(item.id)}>
              Add to PO
            </button>
          ) : (
            <button className="button-secondary w-max !py-1 !px-2" onClick={() => createHoused(item.id).then(onSubmit)}>
              Add Asset
            </button>
          )}
        </div>
      </td>
    ),
  }

  return <TR className={['[&>td]:select-text h-0 relative', submitting && 'animate-pulse'].asClass}>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
