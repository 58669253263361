import { FC, PropsWithChildren, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router'
import { DefaultProfilePic } from 'assets'
import Icon from 'components/Icon'
import Iconset from 'assets/icons/iconset'
import { signOut } from 'firebase/auth'
import firebase from 'config/firebase'
import useCurrentTitle from 'contexts/Title/useCurrentTitle'
import { ArrowIcon } from 'assets/icons'
import { usePnlSync } from 'contexts/PnlSync'
import Progress from 'components/loaders/Progress'

const Header: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const profileDropdown = useRef<HTMLDivElement>(null)
  const currentTitle = useCurrentTitle()
  const {synced, syncedUntil} = usePnlSync()

  const onGoBack = () => {
    navigate("..", {relative: "path"})
  }

  const onGoBackby2 = () => {
    navigate("../..", { relative: "path" });
  };



  const onToggleProfileDropdown = () => {
    const isToggled = profileDropdown.current?.classList.contains('opacity-1')
    if (isToggled) {
      profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
      profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
      profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
    } else {
      profileDropdown.current?.classList.replace('opacity-0', 'opacity-1')
      profileDropdown.current?.classList.replace('-translate-y-10', 'translate-y-2')
      profileDropdown.current?.classList.replace('pointer-events-none', 'pointer-events-auto')
    }
  }

  useEffect(() => {
    const cb = () => {
      const isToggled = profileDropdown.current?.classList.contains('opacity-1')
      if (isToggled) {
        profileDropdown.current?.classList.replace('opacity-1', 'opacity-0')
        profileDropdown.current?.classList.replace('translate-y-2', '-translate-y-10')
        profileDropdown.current?.classList.replace('pointer-events-auto', 'pointer-events-none')
      }
    }

    window.addEventListener('click', cb)

    return () => {
      window.removeEventListener('click', cb)
    }
  }, [])

  const toSubscription = () => {
    onToggleProfileDropdown()
    navigate('/app/subscription')
  }

  const toSettings = () => {
    onToggleProfileDropdown()
    navigate('/app/settings')
  }

  const onLogout = () => {
    onToggleProfileDropdown()
    signOut(firebase.auth)
  }

  const syncProgress = useMemo(() => {
    if (synced) return 100
    if (!syncedUntil) return 0
    const nowMidnight = new Date()
    nowMidnight.setHours(0, 0, 0, 0)
    const syncedUntilMidnight = new Date(syncedUntil)
    syncedUntilMidnight.setHours(0, 0, 0, 0)
    const diff = nowMidnight.getTime() - syncedUntilMidnight.getTime()
    const twoYearsAgo = new Date(nowMidnight)
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2)
    const total = nowMidnight.getTime() - twoYearsAgo.getTime()
    return Math.min(100 - (diff / total) * 100, 100)
  }, [synced, syncedUntil])

  return (
    <div id="header" className="flex w-full bg-surface-primary border-b border-b-border-primary items-center p-4 gap-2 overflow-visible">
      <span className="grow text-2xl font-bold text-text-primary flex items-center gap-4">
        {!currentTitle ? (
          ''
        ) : currentTitle.includes('__back__') ? (
          <div className="flex items-center gap-4">
            <button onClick={onGoBack} className={'button-secondary !px-2 !text-text-primary !rounded-full !border-none'}>
              <Iconset name="CaretLeft" />
            </button>
            {currentTitle.split('__back__')[1]}
          </div>
        ) : currentTitle.includes('__back2__') ? (
            <div className="flex items-center gap-4">
              <button onClick={onGoBackby2} className={'button-secondary !px-2 !text-text-primary !rounded-full !border-none'}>
                <Iconset name="CaretLeft" />
              </button>
              {currentTitle.split('__back2__')[1]}
            </div>
        ) : (
          currentTitle
        )}
        {syncProgress !== 100 && (
          <div className="flex items-center gap-2 text-xs text-text-primary overflow-hidden border border-border-primary py-1 px-3 rounded-lg relative">
            <div
              className="absolute w-full h-full top-0 left-0 bg-blue-100 animate-pulse"
            />
            <span className="z-10">
              Syncing sales data...
            </span>
            <Progress progress={syncProgress / 100} size={32} />
          </div>
        )} 
      </span>
      <div className="relative z-[10000]" onClick={(e) => e.stopPropagation()}>
        <div className="cursor-pointer flex items-center gap-3 group" onClick={onToggleProfileDropdown}>
          <DefaultProfilePic className="w-12 h-12 border-2 border-transparent group-hover:border-brand-primary group-hover:opacity-95 rounded-full transition-all" />
          <ArrowIcon className="group-hover:translate-y-0.5 transition-transform" />
        </div>
        <div
          ref={profileDropdown}
          className="absolute top-full right-0 drop-shadow-xl border border-base-200 transition-all divide-y divide-y-base-200 z-10 bg-white rounded-xl opacity-0 -translate-y-10 pointer-events-none"
        >
          <button onClick={toSettings} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-orange-500 transition-all">
            <Icon name="setting-4" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Settings</span>
          </button>
          <button onClick={toSubscription} className="w-full px-4 py-2 text-base-400 min-w-max w-full text-xs font-medium items-center group flex gap-2 hover:text-orange-500 transition-all">
            <Icon name="card" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Subscription</span>
          </button>
          <button onClick={onLogout} className="w-full px-4 py-2 text-base-400 text-xs min-w-max w-full font-medium items-center group flex gap-2 hover:text-primary-600 transition-all">
            <Icon name="logout-1" type="outline" className="fill-base-400 group-hover:fill-orange-500 transition-all" />
            <span>Sign Out</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
