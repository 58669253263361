import { useQuery } from "@tanstack/react-query"
import restockingApi from "api/restocking"

const queryFn = async ({ queryKey }: {queryKey: string[]}) => {
    const [, , asin] = queryKey
    return restockingApi.getSupplyChain(asin)
}

const useAsinSupplyChain = (asin: string, enabled = false) => {
    const {
        data,
    } = useQuery({
        queryKey: ['restocking', 'supply-chain', asin],
        queryFn,
        enabled,
    })

    return data
}

export default useAsinSupplyChain