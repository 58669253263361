import { FC, PropsWithChildren, useEffect, useState } from "react";
import titleContext from "./titleContext";

const TitleProvider: FC<PropsWithChildren> = ({children}) => {
    const [text, setText] = useState<string>()

    useEffect(() => {
        document.title = [text, "Third Party Profits"].filter(Boolean).join(" | ")
    }, [text])

    return (
        <titleContext.Provider value={{text, setText}}>
            {children}
        </titleContext.Provider>
    )
}

export default TitleProvider