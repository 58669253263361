import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AsinEntryItem } from 'types/housedAsins'

const UpdatePreferencesModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [buyBoxPercentage, setBuyBoxPercentage] = useState<number>()
  const navigate = useNavigate()

  useEffect(() => {
    if (!open) {
      setBuyBoxPercentage(undefined)
      return
    }
    housedAsinsApi.getBuyBoxPercentage().then((res) => {
      setBuyBoxPercentage(res * 100)
    })
  }, [open])

  const handleSubmit = async () => {
    if (submitting) return
    if (buyBoxPercentage === undefined) return
    setSubmitting(true)
    housedAsinsApi
      .updateBuyBoxPercentage(buyBoxPercentage / 100)
      .then(() => navigate(0))
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2 border-b">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Preferences</span>
              <span className="text-sm text-text-secondary">Add your preferences.</span>
            </div>
          </div>
          <div className={['flex flex-col bg-surface-primary px-6', buyBoxPercentage === undefined && '[&_input]:animate-pulse [&_select]:animate-pulse pointer-events-none'].asClass}>
            <div className="flex flex-col gap-2">
              <p className="text-text-primary font-medium text-base pt-4">Buy Box Percentage</p>
                <div className="flex flex-col gap-1 w-full">
                  <div className="flex w-full rounded border border-border-primary">
                    <input type="number" value={buyBoxPercentage} min={0} max={100} onChange={(e) => setBuyBoxPercentage(e.currentTarget.valueAsNumber)} className="py-1 px-3.5 grow rounded-lg" placeholder="100" />
                    <div className="border-l border-l-border-primary p-3 bg-surface-light">
                      %
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdatePreferencesModal
