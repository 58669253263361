import { ColumnDef } from '@tanstack/react-table'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import usePreferences from 'contexts/Preferences/useContext'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ITablePreferenceColumn, ITablePreferenceColumnPin, ITablesPreferences } from 'types/preferences'
import { arrayMove } from 'utils/arrays'

interface Props {
  table: string
  columnDef: ColumnDef<any>[]
}

const pinValue = (pin: ITablePreferenceColumnPin) => {
  if (pin === 'left') return -1
  if (pin === 'right') return 1
  return 0
}

const UpdateColumns: FC<Props> = ({ table, columnDef }) => {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const { preferences, savePreference, saving } = usePreferences()

  const close = useCallback(() => {
    if (saving) return
    setOpen(false)
  }, [saving])

  const visibleScrollRef = useRef<HTMLDivElement>(null)

  const [visibleColumns, setVisibleColumns] = useState<ITablePreferenceColumn[]>([])
  const [visibleIndex, setVisibleIndex] = useState(0)
  const [hiddenColumns, setHiddenColumns] = useState<ITablePreferenceColumn[]>([])
  const [hiddenIndex, setHiddenIndex] = useState(0)

  useEffect(() => {
    const allColumns = columnDef.map(({ id }) => ({ id })) as ITablePreferenceColumn[]
    const visible = preferences?.tables?.[table]?.columns || allColumns
    const hidden = allColumns.filter(({ id }) => !visible.find((col) => col.id === id))
    setVisibleColumns(visible)
    setHiddenColumns(hidden)
  }, [preferences, table, columnDef])

  useEffect(() => {
    if (visibleColumns.length > visibleIndex) {
      return
    }
    setVisibleIndex(visibleColumns.length - 1)
  }, [visibleColumns, visibleIndex])

  useEffect(() => {
    if (hiddenColumns.length > hiddenIndex) {
      return
    }
    setHiddenIndex(hiddenColumns.length - 1)
  }, [hiddenColumns, hiddenIndex])

  const getIdHeader = useCallback(
    (_id: string) => {
      return columnDef.find(({ id }) => id === _id)?.header
    },
    [columnDef]
  )

  const moveSelectedUp = useCallback(() => {
    setVisibleIndex((old) => {
      if (old - 1 < 0) return old
      const newIndex = old - 1
      setVisibleColumns((oldColumns) => {
        const copy = [...oldColumns]
        arrayMove(copy, old, newIndex)
        return copy.map((column) => ({ ...column }))
      })
      return newIndex
    })
  }, [])

  const moveSelectedDown = useCallback(() => {
    setVisibleIndex((old) => {
      if (old + 1 > visibleColumns.length) return old
      const newIndex = old + 1
      setVisibleColumns((oldColumns) => {
        const copy = [...oldColumns]
        arrayMove(copy, old, newIndex)
        return copy.map((column) => ({ ...column }))
      })
      return newIndex
    })
  }, [visibleColumns])

  const setPin = useCallback((id: string, pin: 'left' | 'right' | undefined) => {
    setVisibleColumns((old) => {
      return old.map((col) => (col.id === id ? { ...col, pin } : col)).slice(0).sort(({pin: a}, {pin: b}) => pinValue(a) - pinValue(b))
    })
  }, [])

  const hideSelectedColumn = useCallback(() => {
    setVisibleColumns((oldColumns) => {
      const copy = [...oldColumns]
      const hidden = copy.splice(visibleIndex, 1)
      setHiddenColumns((old) => {
        setHiddenIndex(0)
        const seen = new Set<string>()
        return [...hidden, ...old].filter(({ id }) => {
          const present = seen.has(id)
          seen.add(id)
          return !present
        })
      })
      return copy.map((column) => ({ ...column }))
    })
  }, [visibleIndex])

  const showSelectedColumn = useCallback(() => {
    setHiddenColumns((oldColumns) => {
      const copy = [...oldColumns]
      const shown = copy.splice(hiddenIndex, 1)
      setVisibleColumns((old) => {
        setVisibleIndex(0)
        const seen = new Set<string>()
        return [...shown, ...old].filter(({ id }) => {
          const present = seen.has(id)
          seen.add(id)
          return !present
        })
      })
      return copy.map((column) => ({ ...column }))
    })
  }, [hiddenIndex])

  useEffect(() => {
    visibleScrollRef.current?.children.item(visibleIndex)?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [visibleIndex])

  const hiddenColumnsFiltered = useMemo(() => {
    const withTitles = hiddenColumns.map((col) => {
      const header = getIdHeader(col.id)
      return {
        ...col,
        header,
      }
    })
    return withTitles.filter(({ header }) => !(search && !header?.toString()?.toLowerCase().includes(search.toLowerCase())))
  }, [hiddenColumns, search, getIdHeader])

  const handleSave = useCallback(() => {
    const updates: ITablesPreferences = {
      [table]: {
        columns: visibleColumns.map((col) => {
          if (!col.pin) delete col.pin
          return col
        }),
      },
    }
    savePreference('tables', updates).then((updated) => updated === true && setOpen(false))
  }, [savePreference, table, visibleColumns])

  return (
    <>
      <Modal open={open} close={close}>
        <div className="flex flex-col gap-4 items-center p-4 bg-white h-[32rem] overflow-hidden rounded-lg">
          <div className="flex gap-4 grow items-center h-full overflow-hidden">
            <div className="grid gap-4 grid-cols-2 h-full w-full overflow-hidden">
              <div className="flex flex-col gap-4 h-full overflow-hidden">
                <h2 className="text-lg text-text-primary font-bold">Hidden</h2>
                <div className="input-box relative !border-border-secondary">
                  <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder="Search" className="!px-8" />
                  <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                    <Icon name="MagnifyingGlass" className="w-5 h-5" />
                  </div>
                </div>
                <div className="flex flex-col gap-1 overflow-y-auto h-full border border-border-secondary rounded-lg p-3 [&>button]:h-max">
                  {hiddenColumnsFiltered.map(({ id }, i) => (
                    <button key={'hidden_' + id} className={hiddenIndex === i ? 'button-primary' : 'button-tertiary'} onClick={() => setHiddenIndex(i)}>
                      {getIdHeader(id)?.toString()}
                    </button>
                  ))}
                  {hiddenColumnsFiltered.length === 0 && (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                      <span>No columns</span>
                    </div>
                  )}
                </div>
                <button className="button-secondary" onClick={showSelectedColumn}>
                  Show <Icon name="ArrowUp" className="rotate-90" />
                </button>
              </div>
              <div className="flex flex-col gap-4 h-full overflow-hidden">
                <div className="flex items-center gap-1">
                  <h2 className="text-lg text-text-primary font-bold grow">Visible</h2>
                  <span className="text-sm text-text-primary font-medium pr-8">Pinned</span>
                </div>
                <div ref={visibleScrollRef} className="flex flex-col gap-1 overflow-y-auto h-full border border-border-secondary rounded-lg p-3 [&>button]:h-max">
                  {visibleColumns.map(({ id, pin }, i) => (
                    <div key={'visible_' + id} className="flex items-center gap-1">
                      <button className={visibleIndex === i ? 'button-primary grow' : 'button-tertiary grow'} onClick={() => setVisibleIndex(i)}>
                        {getIdHeader(id)?.toString()}
                      </button>
                      <div className="flex gap-1">
                        {pin === undefined ? (
                          <>
                            <Icon
                              name="ArrowUp"
                              className="bg-brand-primary -rotate-90 hover:bg-brand-hover transition-all cursor-pointer text-white rounded-sm p-1 w-8 h-8"
                              onClick={() => setPin(id, 'left')}
                            />
                            <Icon
                              name="ArrowUp"
                              className="bg-brand-primary rotate-90 hover:bg-brand-hover transition-all cursor-pointer text-white rounded-sm p-1 w-8 h-8"
                              onClick={() => setPin(id, 'right')}
                            />
                          </>
                        ) : (
                          <Icon
                            name="X"
                            className="bg-brand-primary rotate-90 hover:bg-brand-hover transition-all cursor-pointer text-white rounded-sm p-1 w-8 h-8"
                            onClick={() => setPin(id, undefined)}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <button className="button-secondary" onClick={hideSelectedColumn}>
                  <Icon name="ArrowUp" className="-rotate-90" />
                  Hide
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <Icon
                name="ArrowUp"
                className="bg-brand-primary hover:bg-brand-hover transition-all cursor-pointer text-white rounded-t-full px-2 pb-2 pt-4 w-10 h-12"
                onClick={() => moveSelectedUp()}
              />
              <Icon
                name="ArrowDown"
                className="bg-brand-primary hover:bg-brand-hover transition-all cursor-pointer text-white rounded-b-full px-2 pt-2 pb-4 w-10 h-12"
                onClick={() => moveSelectedDown()}
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 mt-2">
            <button className="button-primary" disabled={saving} onClick={handleSave}>
              Apply changes
            </button>
          </div>
        </div>
      </Modal>
      <button className="button-secondary" onClick={() => setOpen(true)}>
        <Icon name="Sliders" />
        Columns
      </button>
    </>
  )
}

export default UpdateColumns
