import { FC, useCallback, useState } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'
import useRestocking from 'hooks/useRestocking'
import { RestockingItem } from 'types/restocking'
import Loader from 'components/loaders/Loader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import VelocityWeightsModal from './components/VelocityWeights'
import ReorderSettingsModal from './components/ReorderSettings'
import restockingApi from 'api/restocking'
import useSelect from 'hooks/useSelect'
import Icon from 'assets/icons/iconset'
import UpdateColumns from 'components/modals/UpdateColums'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Dashboard from './components/Dashboard'
import StockHistoryModal from './components/StockHistory'
import housedAsinsApi from 'api/housedAsins'
import CreateWorkingPO from '../housedAsins/Housed/components/CreateWorkingPO'
import { handleError } from 'helpers/errors'

const RestockingPage: FC = () => {
  const { opFilters: filters, search, ordering } = useFilteringContext()
  const { syncState, items, loadNextPage, loadingNextPage } = useRestocking()
  const { prepCosts } = usePrepCosts()
  const [updateVelocities, VelocityWeightsComponent] = useAwaitableModal(VelocityWeightsModal, undefined)
  const [showStockHistory, StockHistoryComponent] = useAwaitableModal(StockHistoryModal, undefined)
  const [updateReorderSettings, ReorderSettingsComponent] = useAwaitableModal(ReorderSettingsModal, undefined)
  const [createWorkingPO, CreateWorkingPOModal] = useAwaitableModal(CreateWorkingPO, [])
  const select = useSelect()

  const [refreshing, setRefreshing] = useState(false)
  const [exporting, setExporting] = useState(false)

  const refresh = useCallback(() => {
    if (refreshing) return
    setRefreshing(true)
    restockingApi.refresh().finally(() => setRefreshing(false))
  }, [refreshing])

  const exportData = useCallback(() => {
    if (exporting) return
    setExporting(true)
    restockingApi
      .exportRestocking(
        columnDef.map((col) => [col.id as string, col.header as string]),
        { search, ordering, filters }
      )
      .finally(() => setExporting(false))
  }, [exporting, search, ordering, filters])

  const { supplierNames } = useSupplierNames()

  const renderRow = useCallback((row: Row<RestockingItem>) => <Item key={row.id} row={row} />, [])

  const onCreateWorkingPO = useCallback(
    async () => {
      if (!select?.selected.length) return
      const ids = select.selected
      const selection = items?.filter((i) => ids.includes(i.id))
      if (!selection?.length) return
      const housedAsins = await housedAsinsApi.getHoused({
        filters: {
          sku: { o: "IN", v: selection.map((i) => i.sku) },
        }
      })
      const allFound = selection.every((i) => {
        const housedAsin = housedAsins.items.find((ii) => ii.sku === i.sku)
        if (!housedAsin) {
          handleError(`Housed Asin for MSKU: ${i.sku} not found`)
          return false
        }
        return true
      })
      if (!allFound) return
      const inputs = housedAsins.items.map((i) => 
        ({
        ...i,
        sellable: (items?.find((ii) => ii.sku === i.sku)?.qtyToReorderOnRedYellow || 0) / (i.bundle || 1)
      }))
      createWorkingPO(inputs)
    },
    [createWorkingPO, items, select]
  )

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = loadingNextPage || refreshing || exporting

  return (
    <div className="w-full h-full flex flex-col overflow-hidden bg-surface-light relative">
      {!syncState.synced && false && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1000] flex items-center justify-center">
          <div className="bg-white rounded-lg flex flex-col items-center justify-center p-4">
            <Loader />
            <div className="text-center mt-3 font-medium">Syncing Sales Data... {syncState.syncedUntil ? `Synced until: ${new Date(syncState.syncedUntil).toDateString()}` : ''}</div>
            <div className="text-center mt-1 text-slate-500">This is a long running operation, don't close this page</div>
          </div>
        </div>
      )}

      <VelocityWeightsComponent />
      <ReorderSettingsComponent />
      <StockHistoryComponent />
      <CreateWorkingPOModal />

      <div className="pt-4 px-4 w-full flex items-center justify-end">
        <button className="button-primary" onClick={exportData} disabled={exporting}>
          <Icon name="DownloadSimple" className="h-5 w-5" />
          Download File
        </button>
      </div>
      <Dashboard />
      <ContentLayout
        wrapperClass="relative"
        underSearch={
        canDoBulk ? (
          <div className="flex-col flex gap-2">
            <div className="flex gap-4">
              {!select.allSelected && (
                <button className="button-primary w-max" onClick={onCreateWorkingPO} disabled={bulkInProgress}>
                  Create a Working PO
                </button>
              )}
            </div>
            </div>
        ) : undefined
              }
        buttons={
          <div className="flex items-center justify-end gap-4 flex-wrap">
            {syncState !== undefined && (
              <div className="flex items-center gap-3">
                <span className="text-sm font-semibold text-base-500">
                  Catalogue synced At: {syncState.syncedUntil ? new Date(syncState.syncedUntil).toDateString() : !syncState.syncedUntil ? 'Never' : '...'}
                </span>
              </div>
            )}
            <button className="button-secondary" onClick={refresh} disabled={refreshing}>
              <Icon name="Refresh" className="h-5 w-5" />
              Refresh
            </button>
            <UpdateColumns table="restocking" columnDef={columnDef} />
          </div>
        }
      >
        <Table
          name="restocking"
          columns={columnDef}
          initialPinState={{
            left: ['image', 'asin', 'url', 'title'],
          }}
          items={items}
          locked={false}
          renderRow={renderRow}
          loading={!items || syncState === undefined}
          loadingNext={loadingNextPage}
          onBottom={loadNextPage}
          select={select}
          noSelectAll
          extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p.name), updateVelocities, updateReorderSettings, showStockHistory }}
        />
      </ContentLayout>
    </div>
  )
}

export default withFiltering(RestockingPage, 'restocking-page')
