import { FC, useCallback, useEffect, useRef } from 'react'
import Item from './components/Item'
import { HousedAsin } from 'types/housedAsins'
import ContentLayout from 'components/layout/Content'
import Loader from 'components/loaders/Loader'
import Table from 'components/tables/Table'
import columnDef from './tableDef'
import useBulkDelete from 'hooks/useBulkDelete'
import housedAsinsApi from 'api/housedAsins'
import useSelect from 'hooks/useSelect'
import usePrepCosts from 'hooks/usePrepCosts'
import useAwaitableModal from 'hooks/useAwaitableModal'
import UpdatePreferencesModal from './components/UpdatePreferencesModal'
import useSupplierNames from 'hooks/useSupplierNames'
import useHoused from 'hooks/useHoused'
import withFiltering from 'contexts/Filter/wrapper'
import CreateWorkingPO from './components/CreateWorkingPO'
import { Row } from '@tanstack/react-table'
import useBulkAction from 'hooks/useBulkAction'
import AddHousedModal from './components/AddHousedModal'
import ListUploads from './components/ListUploads'
import useBuyers from 'hooks/useBuyers'
import BulkUpdateHousedAsinsModal from './components/BulkUpdate'

const HousedAsins: FC = () => {
  const { housed, setHoused, importing, importExcel, loadingNextPage, UploadModal, loadHoused, loadHousedNextPage, neverSynced } = useHoused()
  const { buyers: availableBuyers } = useBuyers()
  const { prepCosts } = usePrepCosts()
  const select = useSelect()
  // const [locked, setLocked] = useState(true)
  const [onDelete, deleting, AreYouSureModal] = useBulkDelete(select, housedAsinsApi.deleteHousedBulk, { header: 'Delete Asin', description: 'Are you sure you want to delete these entries?' })
  const [onFetchMaster, fetchingMaster] = useBulkAction(select, housedAsinsApi.fetchFromMasterCatalog.bind(this, false))
  const [onFetchMasterOverride, fetchingMasterOverride] = useBulkAction(select, housedAsinsApi.fetchFromMasterCatalog.bind(this, true))
  const [onGenerateMSKU, generatingMSKU] = useBulkAction(select, housedAsinsApi.generateMSKU.bind(this))
  const [createWorkingPO, CreateWorkingPOModal] = useAwaitableModal(CreateWorkingPO, [])
  const anyTimeSelected = useRef<Record<string, HousedAsin>>({})
  const [listPreviousUploads, ListUploadsModal] = useAwaitableModal(ListUploads, {})

  const [addAsinEntry, CreateHousedModal] = useAwaitableModal(AddHousedModal, undefined)
  const [bulkUpdate, BulkUpdateModal] = useAwaitableModal(BulkUpdateHousedAsinsModal, undefined)

  const onAdd = () => {
    addAsinEntry(undefined)
  }

  useEffect(() => {
    anyTimeSelected.current = {
      ...anyTimeSelected.current,
      ...Object.fromEntries(select.selected.map((id) => [id, housed?.find((i) => i.id === id)!])),
    }
  }, [anyTimeSelected, select, housed])

  const onCreateWorkingPO = useCallback(() => {
    const items = select.selected.map((id) => anyTimeSelected.current[id])
    createWorkingPO(items)
  }, [createWorkingPO, select, anyTimeSelected])

  const { supplierNames } = useSupplierNames()

  const onUpdate = useCallback(
    (entry?: HousedAsin) => {
      if (!entry) return
      setHoused((old) => old && old.map((s) => (s.id === entry.id ? { ...entry } : s)))
    },
    [setHoused]
  )

  const [changePreferences, UpdatePreferences] = useAwaitableModal(UpdatePreferencesModal, undefined)

  const renderRow = useCallback((row: Row<HousedAsin>) => <Item key={row.original.id} row={row} onDone={onUpdate} />, [onUpdate])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = deleting || fetchingMaster || fetchingMasterOverride || generatingMSKU

  return (
    <ContentLayout
      afterSearch={
        <div className="flex gap-4 items-center">
          <span className="text-text-secondary text-xs"><b>{neverSynced}</b> never synced</span>
        </div>
      }
      underSearch={
        canDoBulk ? (
          <div className="flex-col flex gap-2">
            <div className="flex gap-4">
              {!select.allSelected && (
                <button className="button-primary w-max" onClick={onCreateWorkingPO} disabled={bulkInProgress}>
                  Create a Working PO
                </button>
              )}
              <button className="button-secondary w-max" onClick={onFetchMaster} disabled={bulkInProgress}>
                Fetch From Master Catalog
              </button>
              <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
                Delete
              </button>
            </div>
            <div className="flex gap-4">
              <button className="button-secondary w-max" onClick={onFetchMasterOverride} disabled={bulkInProgress}>
                Reload From Master Catalog
              </button>
              <button className="button-secondary w-max" onClick={onGenerateMSKU} disabled={bulkInProgress}>
                Generate MSKUs
              </button>
            </div>
          </div>
        ) : undefined
      }
      buttons={
        <>
          {!importing ? (
            <div className="flex gap-4 items-center flex-wrap justify-end">
              <button
                className="button-secondary"
                onClick={() =>
                  listPreviousUploads().then((res) => {
                    if (res) loadHoused()
                  })
                }
              >
                Previous Uploads
              </button>
              <button className="button-primary" onClick={onAdd}>
                Add Entry
              </button>
              <button className="button-secondary" onClick={changePreferences}>
                Preferences
              </button>
              <button className="button-secondary" onClick={importExcel}>
                Import CSV
              </button>
              <button className="button-secondary w-max" onClick={() => bulkUpdate()}>
                Bulk Edit
              </button>
            </div>
          ) : (
            <Loader size={24} />
          )}
        </>
      }
    >
      <UploadModal />
      <AreYouSureModal />
      <UpdatePreferences />
      <CreateWorkingPOModal />
      <CreateHousedModal />
      <ListUploadsModal />
      <BulkUpdateModal />
      <Table
        name="housed-asins"
        columns={columnDef}
        initialPinState={{
          left: ['image', 'asin'],
        }}
        items={housed}
        renderRow={renderRow}
        loading={!housed}
        loadingNext={loadingNextPage}
        onBottom={loadHousedNextPage}
        select={select}
        locked={false}
        extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p) || [], availableBuyers }}
      />
    </ContentLayout>
  )
}

export default withFiltering(HousedAsins, 'housed-asins')
