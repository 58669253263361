import { ColumnDef } from "@tanstack/react-table";
import { RestockingItem } from "types/restocking";

const columnDef: ColumnDef<RestockingItem>[] = [
    {
        id: "image",
        header: "Image",
        size: 60,
    },
    {
        id: "url",
        header: "URL",
        size: 48
    },
    {
        id: "title",
        header: "Title",
        meta: {
            filters: "string",
        }
    },
    {
        id: "asin",
        header: "ASIN",
        size: 130,
        meta: {
            filters: "string",
        }
    },
    {
        id: "suppliers",
        header: "Suppliers",
        meta: {
            filters: "array",
        }
    },
    {
        id: "sku",
        header: "SKU",
        meta: {
            filters: "string",
        }
    },
    {
        id: "upcs",
        header: "UPCs",
        meta: {
            filters: "array",
        }
    },
    {
        id: "rollingAvgCogs",
        header: "Rolling AVG COG",
        meta: {
            filters: "number",
        }
    },
    {
        id: "estDailySalesVelocity",
        header: "EST Daily Sales Velocity",
        meta: {
            filters: "number",
        }
    },
    {
        id: "profitPerSale",
        header: "Profit Per Sale",
        meta: {
            filters: "number",
        }
    },
    {
        id: "forecastedProfit",
        header: "Forecast Profit",
        meta: {
            filters: "number",
        }
    },
    {
        id: "margin",
        header: "Margin",
        meta: {
            filters: "number",
        }
    },
    {
        id: "roi",
        header: "ROI",
        meta: {
            filters: "number",
        }
    },
    {
        id: "stockStatus",
        header: "Stock Status",
        meta: {
            filters: "string",
        }
    },
    {
        id: "daysUntilReorder",
        header: "Days  Until Next Reorder",
        meta: {
            filters: "number",
        }
    },
    {
        id: "daysOfInventoryInAmazon",
        header: "Days of inventory in Amazon",
        meta: {
            filters: "number",
        }
    },
    {
        id: "qtyToReorderOnRedYellow",
        header: "QTY To Reorder ON Red/Yellow",
        meta: {
            filters: "number",
        }
    },
    {
        id: "amazonInventory",
        header: "Amazon Inventory",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ytdInStockRate",
        header: "YDT In Stock Rate",
        meta: {
            filters: "number",
        }
    },
    {
        id: "missedDailyProfit",
        header: "Missed Daily Profit",
        meta: {
            filters: "number",
        }
    },
    {
        id: "missedMonthlyProfit",
        header: "Missed Monthly Profit",
        meta: {
            filters: "number",
        }
    },
    {
        id: "missedYearlyProfit",
        header: "Missed Yearly Profit",
        meta: {
            filters: "number",
        }
    },
    {
        id: "prepCosts",
        header: "Type Of Labor",
        meta: {
            filters: "array",
        }
    },
    {
        id: "historicalDaysOfSupply",
        header: "Historical Days Of Supply",
        meta: {
            filters: "number",
        }
    },
    {
        id: "fbaMinimumInventoryLevel",
        header: "Low Level Inventory Exempt",
        meta: {
            filters: "number",
        }
    },
    {
        id: "lowestUnitLevelToReorder",
        header: "Lowest Unit Level To Reorder",
        meta: {
            filters: "number",
        }
    },
    {
        id: "targetUnitsStock",
        header: "Target Units Stock",
        meta: {
            filters: "number",
        }
    },
    {
        id: "estRecommendedReorderCogs",
        header: "EST Recommended Reorder COG",
        meta: {
            filters: "number",
        }
    },
    {
        id: "overUnderBuyingValue",
        header: "Over/Under Buying",
        meta: {
            filters: "number",
        }
    },
    {
        id: "weightValue",
        header: "Weight",
        meta: {
            filters: "number",
        }
    },
    {
        id: "workingOrderValue",
        header: "Purchase Order Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "inboundOrderValue",
        header: "Inbound Stock Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "warehouseOrderValue",
        header: "Warehouse Stock Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "supplyChainValue",
        header: "Supply Chain Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "amazonInventoryValue",
        header: "FBA Stock Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "totalValue",
        header: "Total Stock Value",
        meta: {
            filters: "number",
        }
    },
    {
        id: "workingOrderQty",
        header: "Working Order QTY",
        meta: {
            filters: "number",
        }
    },
    {
        id: "inboundOrderQty",
        header: "Inbound Order QTY",
        meta: {
            filters: "number",
        }
    },
    {
        id: "warehouseOrderQty",
        header: "Warehouse Order QTY",
        meta: {
            filters: "number",
        }
    },
    {
        id: "supplyChainQty",
        header: "Supply Chain QTY",
        meta: {
            filters: "number",
        }
    },
    {
        id: "fbaInventory",
        header: "FBA QTY",
        meta: {
            filters: "number",
        }
    },
    {
        id: "totalQty",
        header: "Total QTY",
        meta: {
            filters: "number",
        }
    }
]

export default columnDef;