import warehouseApi from "api/warehouse"
import { useRef, useState, useCallback, useEffect, createContext, FC, PropsWithChildren, useContext } from "react"


const useSyncOutbound = () => {
  const syncStarted = useRef(false)
  const [syncResponse, setSyncResponse] = useState<{synced: boolean, syncedUntil: string}>(
    { synced: false, syncedUntil: '' }
  )

  const sync = useCallback(() => {
    warehouseApi.sync().then((res) => {
        setSyncResponse(res)
        if (!res.synced) {
          return sync()
        }
      }).catch(() => {
        return sync()
      })
  }, [])

  useEffect(() => {
    if (syncStarted.current) return
    syncStarted.current = true
    warehouseApi.getSyncStatus().then((res) => {
      setSyncResponse(res)
      if (!res.synced) {
        sync()
      }
    })
  }, [sync])

  return syncResponse
}

const OutboundSyncContext = createContext<ReturnType<typeof useSyncOutbound>>({ synced: false, syncedUntil: '' })

export const useOutboundSync = () => useContext(OutboundSyncContext)

const OutboundSyncProvider: FC<PropsWithChildren> = ({ children }) => {
  const syncState = useSyncOutbound()

  return (
    <OutboundSyncContext.Provider value={syncState}>
      {children}
    </OutboundSyncContext.Provider>
  )
}

export default OutboundSyncProvider