import { useRef, useEffect } from 'react'

const prefix = (key: string) => 'scrollstate:' + key

const useSaveScroll = (key?: string) => {
    const ref = useRef<HTMLDivElement | null>(null)
    
    useEffect(() => {
        if (!key) return
        if (ref.current) {
            const saved = sessionStorage.getItem(prefix(key))
            if (saved) {
                ref.current.scrollTop = parseInt(saved)
            }
            const el = ref.current
            const onScroll = () => {
                if (el.scrollTop > 0) {
                    sessionStorage.setItem(prefix(key), el.scrollTop.toString())
                }
            }
            el.addEventListener('scroll', onScroll)

            return () => {
                el.removeEventListener('scroll', onScroll)
            }
        }
    }, [key])

    return ref
}

export default useSaveScroll