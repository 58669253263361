import restockingApi from 'api/restocking'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useCallback, useEffect, useState } from 'react'
import { CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer, Area, AreaChart, ReferenceArea } from 'recharts'
import { usNumber } from 'utils/formatting'
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import { useQuery } from '@tanstack/react-query'

const StockHistoryModal: AwaitableModal<undefined, string | undefined> = ({ open, resolve, initialData: sku }) => {
  const [windowSize, setWindowSize] = useState(365)
  const [windowOffsets, setWindowOffsets] = useState({ left: 'dataMin', right: 'dataMax' } as { left: number | string; right: number | string })
  const [selection, setSelection] = useState({ left: '', right: '' } as { left: number | string; right: number | string })

  const {data: history} = useQuery({
    queryKey: ['stockHistory', sku] as [string, string | undefined],
    queryFn: ({queryKey: [, sku]}) => sku ? restockingApi.getStockHistory(sku) : undefined,
    enabled: !!sku,
  })

  const zoom: CategoricalChartFunc = useCallback((e) => {
    setSelection((old) => {
      let [refAreaLeft, refAreaRight] = [old.left, old.right]
      if (refAreaLeft === refAreaRight || refAreaRight === '') {
        return { left: '', right: '' }
      }
      if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft]
      setWindowOffsets({ left: refAreaLeft, right: refAreaRight })
      return { left: '', right: '' }
    })
  }, [])

  useEffect(() => {
    const dataMaxDate = history?.at(0)?.date
    if (dataMaxDate) {
      const dataMax = new Date(dataMaxDate).getTime()
      setWindowOffsets({ left: dataMax - 24 * 60 * 60 * 1000 * windowSize, right: 'dataMax' })
    }
  }, [windowSize, history])

  const colors = ['#1366ED', '#f0a30d']

  return (
    <Modal open={open} close={selection.left !== "" ? () => {} : resolve}>
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[75vw] overflow-y-auto">
        <div className="flex items-start justify-start p-6 pb-4 gap-2">
          <div className="flex items-center gap-4 grow">
            <span className="text-base text-text-primary font-medium">Stock Levels Over Time</span>
            <span className="text-xl text-text-secondary">{sku}</span>
          </div>
          <select className="select-primary" value={windowSize} onChange={(e) => setWindowSize(+e.target.value)}>
            <option value="7">1 Week</option>
            <option value="30">1 Month</option>
            <option value="90">3 Months</option>
            <option value="180">6 Months</option>
            <option value="365">1 Year</option>
          </select>
        </div>
        <main className="overflow-x-hidden flex w-full px-6 gap-3 h-max">
          <div className={['w-full max-w-full aspect-[21/9] rounded-lg', !history && 'bg-surface-secondary animate-pulse'].asClass}>
            <svg className="h-0">
              <defs>
                {colors.map((color, i) => (
                  <linearGradient key={i} id={`${i}`} x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stopColor={color} stopOpacity="1" />
                    <stop offset="100%" stopColor={color} stopOpacity="0" />
                  </linearGradient>
                ))}
              </defs>
            </svg>
            {history && (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  onMouseDown={(e) => setSelection((old) => ({ ...old, left: e.activeLabel || 'dataMin' }))}
                  onMouseMove={(e) => setSelection((old) => ({ ...old, right: e.activeLabel || 'dataMax' }))}
                  data={history
                    .slice(0)
                    .reverse()
                    .map((t) => ({ ...t, date: new Date(t.date).getTime() }))}
                  onMouseUp={zoom}
                  className="w-full aspect-[21/9]"
                >
                  <CartesianGrid strokeDasharray="0" vertical={false} />
                  <XAxis
                    allowDataOverflow
                    domain={[windowOffsets.left, windowOffsets.right]}
                    dataKey="date"
                    type="number"
                    tickSize={8}
                    tickMargin={6}
                    minTickGap={50}
                    tick={{ fontSize: 10,
                      fontWeight: 500, }}
                    tickCount={20}
                    tickFormatter={(time: number) => new Date(time).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' })}
                    axisLine={{stroke: "rgba(0,0,0,0.2)"}}
                    tickLine={{stroke: "rgba(0,0,0,0.2)"}}
                  />
                  <YAxis
                    range={[0, 100]}
                    interval="preserveStartEnd"
                    type="number"
                    tickFormatter={(value) => usNumber(value, 0)}
                    tickSize={8}
                    tick={{
                      fontSize: 10,
                      fontWeight: 500,
                    }}
                    axisLine={{stroke: "rgba(0,0,0,0.2)"}}
                    tickLine={{stroke: "rgba(0,0,0,0.2)"}}
                    />
                  <Tooltip
                    content={({ payload }) => {
                      const date = payload?.[0]?.payload?.date

                      return (
                        <div className="bg-surface-primary border border-border-primary p-2 rounded-lg">
                          <div className="text-text-primary font-medium text-xs">{new Date(date).toLocaleDateString('en-US')}</div>
                          {payload?.map((datum) => (
                            <div key={datum.name} className="text-text-secondary text-sm flex items-center">
                              <div className="w-1.5 h-1.5 rounded-full mr-1" style={{backgroundColor: datum.color}}/> {datum.name}: {datum.value}
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  />
                  <Area type="monotone" activeDot={{ stroke: '0' }} dataKey="inventoryLevel" name="Inventory" stroke="#1366ED" strokeWidth="2.5" fill="url(#0)" />
                  <Area type="monotone" activeDot={{ stroke: '0' }} dataKey="sales" name="Daily Sales" stroke="#f0a30d" strokeWidth="2.5" fill="url(#1)" />
                  {selection.left && selection.right ? <ReferenceArea x1={selection.left} x2={selection.right} strokeOpacity={0.3} /> : null}
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        </main>
        <div className="flex items-center justify-center gap-4 p-4 w-full">
          <button className="button-secondary grow" onClick={() => resolve()}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default StockHistoryModal
