import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import AmazonProductImage from 'components/AmazonProductImage'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import { usNumber } from 'utils/formatting'
import { IPrepCost } from 'types/prepCosts'
import { IPurchaseOrderItem } from 'types/purchaseOrders'
import { IPurchaseOrderColumnItem } from 'utils/purchaseOrders'
import purchaseOrdersApi from 'api/purchaseOrders'
import BadgeSelect from 'components/BadgeSelect'
import { useParams } from 'react-router'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { orderTypes, storageTypes } from 'constants/badgeSelect'
import { group } from 'console'
import ColorGroupToggle from 'components/ColorGroupToggle'

interface props {
  raw: IPurchaseOrderItem
  row: Row<IPurchaseOrderColumnItem>
  onUpdate: (item: IPurchaseOrderItem) => void
}

const Item: FC<props> = ({ onUpdate, row, raw }) => {
  const item = row.original
  const { id } = useParams() as { id: string }
  const { extra, locked, select } = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...raw,
  } as IPurchaseOrderItem)

  const prepCosts = extra?.prepCosts || []

  useEffect(() => {
    setValues({ ...raw })
  }, [raw])

  const update = (overrides?: Partial<IPurchaseOrderItem>) => {
    if (submitting) return
    if (locked) return
    setSubmitting(true)
    purchaseOrdersApi
      .updatePurchaseOrderItem(id, { ...values, ...overrides })
      .then((res) => {
        onUpdate(res)
      })
      .finally(() => setSubmitting(false))
  }

  const onSubmit = () => {
    update()
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<IPurchaseOrderColumnItem> = {
    selection: (cell) =>
      !!select && (
        <td key={cell.id} onClick={() => select?.onSelectClick(item.id)} style={{ ...getCommonPinningStyles(cell.column) }}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </td>
      ),
    image: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <AmazonProductImage asin={item?.asin} size={48} imageSize={96} />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!raw.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
        </div>
      </td>
    ),
    title: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }} className="w-32">
        <input type="text" value={item.title || ""} readOnly />
      </td>
    ),
    upc: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.upc || ""} readOnly />
      </td>
    ),
    vendorSKU: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" value={item.vendorSKU || ""} readOnly />
      </td>
    ),
    unitCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input readOnly={locked} type="number" className="!pl-2.5" value={values.unitCost || ''} onChange={onNumberChange((val) => onValueChange('unitCost', val)())} placeholder="0.00" />
        </div>
      </td>
    ),
    bundle: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input readOnly={locked} className="table-input" type="number" value={values.bundle} onChange={onNumberChange((val) => onValueChange('bundle', val)())} placeholder="0" />
      </td>
    ),
    buyQty: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input readOnly={locked} className="table-input" type="number" value={values.buyQty} onChange={onNumberChange((val) => onValueChange('buyQty', val)())} placeholder="0" />
      </td>
    ),
    sellable: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.sellable !== Math.floor(item.sellable) ? 'bg-red-400' : ''}>
        {usNumber(item.sellable, 0)}
      </td>
    ),
    casePack: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <input readOnly={locked} className="table-input" type="number" value={values.casePack} onChange={onNumberChange((val) => onValueChange('casePack', val)())} placeholder="0" />
      </td>
    ),
    cases: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.cases !== Math.floor(item.cases) ? 'bg-red-400' : ''}>
        {usNumber(item.cases, 0)}
      </td>
    ),
    price: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input
            type="number"
            readOnly={locked}
            className="!pl-2.5"
            value={values.targetSoldPrice || ''}
            onChange={onNumberChange((val) => onValueChange('targetSoldPrice', val)())}
            placeholder={(item.price || '0.00').toString()}
          />
        </div>
      </td>
    ),
    targetPrice: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input readOnly={locked} type="number" className="!pl-2.5" value={values.targetPrice || ''} onChange={onNumberChange((val) => onValueChange('targetPrice', val)())} placeholder="0.00" />
        </div>
      </td>
    ),
    totalCogs: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.totalCogs)}
      </td>
    ),
    prepCost: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect selected={values.housedAsin.prepCost || ''} badges={prepCosts?.map((p: IPrepCost) => p.name) || []} onSelect={() => {}} singleColor="blue" editable={false} />
          <span>${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.housedAsin.prepCost)?.amount)}</span>
        </div>
      </td>
    ),
    productionCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.productionCost)}
      </td>
    ),
    shippingCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.shippingCost)}
      </td>
    ),
    landedCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.landedCost)}
      </td>
    ),
    revenue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.revenue)}
      </td>
    ),
    fees: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.fees)}
      </td>
    ),
    net: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.net)}
      </td>
    ),
    gross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={(item.gross || 0) > 0 ? 'text-green-500' : 'text-red-500'}>
        ${usNumber(item.gross)}
      </td>
    ),
    totalGross: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.totalGross > 0 ? 'text-green-500' : 'text-red-500'}>
        ${usNumber(item.totalGross)}
      </td>
    ),
    roi: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.roi)}%
      </td>
    ),
    margin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.margin)}%
      </td>
    ),
    weight: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.weight)} lbs
      </td>
    ),
    brand: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.brand}
      </td>
    ),
    order_type: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={item.order_type || ''}
            badges={orderTypes}
            onSelect={(order_type: any) => {
              setValues((old) => ({ ...old, order_type }))
              update({ order_type })
            }}
            editable={!locked && !submitting}
          />
        </div>
      </td>
    ),
    sku: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="!py-1.5 !px-3">
        <input className="table-input" value={item.sku || ''} readOnly />
      </td>
    ),
    asinCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        $ {usNumber(item.asinCost)}
      </td>
    ),
    salesRank: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.salesRank, 0)}
      </td>
    ),
    salesCategory: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.salesCategory}
      </td>
    ),
    notes: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} type="text" value={values.notes || ''} onChange={(e) => onValueChange('notes', e.currentTarget.value)()} placeholder="" />
      </td>
    ),
    group: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <ColorGroupToggle value={values.group} onChange={(group) => onValueChange('group', group)(true)} disabled={submitting} />
      </td>
    ),
    storageType: (cell) => (
      <td key={cell.id} onBlur={(e) => e.stopPropagation()} style={{ ...getCommonPinningStyles(cell.column) }}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={item.storageType || ''}
            badges={storageTypes}
            onSelect={(storageType: any) => {}}
            editable={false}
          />
        </div>
      </td>
    ),
  }

  return (
    <tr className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </tr>
  )
}

export default Item
