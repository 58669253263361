import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { orderTypes, storageTypes } from 'constants/badgeSelect'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useBuyers from 'hooks/useBuyers'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { useEffect, useState } from 'react'

const columns = [
  { key: 'asin', title: 'ASIN', type: 'text', onlyFilter: true },
  { key: 'sku', title: 'SKU', type: 'text' },
  { key: 'fba', title: 'FBA', type: 'boolean' },
  { key: 'supplier', title: 'Supplier', type: 'text' },
  { key: 'vendorSKU', title: 'Vendor SKU', type: 'text' },
  { key: 'upc', title: 'UPC', type: 'text' },
  { key: 'caseQty', title: 'Case Qty', type: 'number' },
  { key: 'unitCost', title: 'Unit Cost', type: 'number' },
  { key: 'bundle', title: 'Bundle', type: 'boolean' },
  { key: 'sellable', title: 'Sellable', type: 'boolean' },
  { key: 'prepCost', title: 'Prep Cost', type: 'number' },
  { key: 'buyer', title: 'Buyer', type: 'text' },
  { key: 'targetPrice', title: 'Target Price', type: 'number' },
  { key: 'storageType', title: 'Storage Type', type: 'text' },
  { key: 'orderType', title: 'Order Type', type: 'text' },
]

const transformValue = (key: string, value: string) => {
  if (key === 'fba') {
    return value === 'true'
  }
  if (key === 'caseQty' || key === 'unitCost' || key === 'prepCost' || key === 'targetPrice') {
    return Number(value) || null
  }
  return value || null
}

const BulkUpdateHousedAsinsModal: AwaitableModal<{}, undefined> = ({ resolve, onCancel, open }) => {
  const [submitting, setSubmitting] = useState(false)
  const [originalKey, setOriginalKey] = useState('')
  const [original, setOriginal] = useState('')
  const [newKey, setNewKey] = useState('')
  const [value, setValue] = useState('')

  const { prepCosts } = usePrepCosts()
  const { supplierNames } = useSupplierNames()
  const { buyers } = useBuyers()

  useEffect(() => {
    setOriginal('')
  }, [originalKey])

  useEffect(() => {
    setValue('')
  }, [newKey])

  const onSubmit = () => {
    if (!newKey) {
      return handleError('Select key to update')
    }
    if (original && original === value) {
      return handleError('Old and New values should not be the same')
    }
    const originalValue = transformValue(originalKey, original)
    const newValue = transformValue(newKey, value)
    if (typeof originalValue === 'number' && typeof newValue === 'number') {
      if (isNaN(originalValue) || isNaN(newValue)) {
        return handleError('Please enter valid numbers')
      }
    }
    setSubmitting(true)
    housedAsinsApi
      .bulkUpdateHoused({ originalKey, originalValue, newKey, newValue })
      .then(() => {
        window.location.reload()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={submitting ? () => {} : onCancel}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Bulk Update Housed Asins</span>
              <span className="text-sm text-text-secondary">Select column value to update.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Filter Column</span>
                <select className="border border-border-primary py-2.5 px-2 rounded-lg" onChange={(e) => setOriginalKey(e.target.value)}>
                  <option value="" disabled selected>
                    All Asins
                  </option>
                  {columns.map((column) => (
                    <option key={column.key} value={column.key}>{column.title}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Changed Column</span>
                <select className="border border-border-primary py-2.5 px-2 rounded-lg" onChange={(e) => setNewKey(e.target.value)}>
                  <option value="" disabled selected>
                    Select Column
                  </option>
                  {columns.filter((v) => !v.onlyFilter).map((column) => (
                    <option key={column.key} value={column.key}>{column.title}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Filter Value</span>
                {originalKey === 'prepCost' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select Prep Cost
                    </option>
                    {prepCosts?.map((cost) => (
                      <option key={cost.name} value={cost.name}>
                        {cost.name}
                      </option>
                    ))}
                  </select>
                ) : originalKey === 'supplier' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select Supplier
                    </option>
                    {supplierNames?.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                ) : originalKey === 'buyer' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select Buyer
                    </option>
                    {buyers?.map((buyer) => (
                      <option key={buyer.id} value={buyer.name}>
                        {buyer.name}
                      </option>
                    ))}
                  </select>
                ) : originalKey === 'fba' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select FBA
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                ) : originalKey === 'storageType' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select Storage Type
                    </option>
                    {storageTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                ) : originalKey === 'orderType' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)}>
                    <option value="" disabled selected>
                      Select Order Type
                    </option>
                    {orderTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input type="text" className="border border-border-primary py-2.5 px-2 rounded-lg" value={original} onChange={(e) => setOriginal(e.target.value)} />
                )}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">New Value</span>
                {newKey === 'prepCost' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select Prep Cost
                    </option>
                    {prepCosts?.map((cost) => (
                      <option key={cost.name} value={cost.name}>
                        {cost.name}
                      </option>
                    ))}
                  </select>
                ) : newKey === 'supplier' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select Supplier
                    </option>
                    {supplierNames?.map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                ) : newKey === 'buyer' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select Buyer
                    </option>
                    {buyers?.map((buyer) => (
                      <option key={buyer.id} value={buyer.name}>
                        {buyer.name}
                      </option>
                    ))}
                  </select>
                ) : newKey === 'fba' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select FBA
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                ) : newKey === 'storageType' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select Storage Type
                    </option>
                    {storageTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                ) : newKey === 'orderType' ? (
                  <select className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)}>
                    <option value="" disabled selected>
                      Select Order Type
                    </option>
                    {orderTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input type="text" className="border border-border-primary py-2.5 px-2 rounded-lg" value={value} onChange={(e) => setValue(e.target.value)} />
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 pb-6 pt-4">
              <button className="button-secondary" disabled={submitting} onClick={onCancel}>
                Cancel
              </button>
              <button className="button-primary" disabled={submitting} onClick={onSubmit}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BulkUpdateHousedAsinsModal
