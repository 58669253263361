import { FC } from 'react'

interface props {
  progress: number
  size?: number
}

const Progress: FC<props> = ({ progress = 0, size = 48 }) => {
  const center = size / 2
  const lineWidth = 5
  const radius = center - lineWidth / 2

  const strokeDasharray = Math.PI * radius * 2
  const strokeDashoffset = strokeDasharray * (1 - progress)

  return (
    <div className="relative w-min h-min">
      <svg width={size} height={size} viewBox={[0, 0, size, size].join(" ")} style={{ rotate: '-90deg' }} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={center} cy={center} r={radius} className="stroke-brand-primary opacity-30" strokeWidth={lineWidth} />
        <circle cx={center} cy={center} r={radius} className="transition-all stroke-brand-primary" strokeWidth={lineWidth} strokeLinecap="round" strokeDasharray={`${strokeDasharray} ${strokeDasharray}`} strokeDashoffset={strokeDashoffset} />
      </svg>
      <div
        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-brand-primary font-semibold"
        style={{
          fontSize: `${size / 48 * 0.75}rem`,
        }}
        >
        {Math.round(progress * 100)}%
      </div>
    </div>
  )
}

export default Progress
